import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";

import { useBreakpoints } from "util/BreakpointProvider";
import HeroImg from "pages/www/assets/floorplan.webp";
import { BOOK_DEMO_URL } from "pages/www/util";

import { ImageSection } from "../../styles";
import { gradientColor1, gradientColor2 } from "../../styles/constants";
import "./index.scss";

const StyledImageSection = styled(ImageSection)((props) => ({
  gridTemplateRows: "auto auto",
  gridTemplateColumns: "1fr",
  paddingTop: 56,
  justifyContent: "center",
  gridGap: 126,
}));
const Content = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  alignItems: "center",
  justifySelf: "center",
  ...(props.theme.bp.lessThan.xl && {
    padding: "40px 32px",
  }),
}));
const Overline = styled(Typography)((props) => ({
  ...props.theme.typography.overline,
  marginBottom: 6,
  ...(props.theme.bp.greaterThan.md && {
    marginBottom: 0,
    fontSize: "0.875rem",
    lineHeight: "0.875rem",
  }),
}));
const Display = styled(Typography)((props) => ({
  fontSize: props.theme.bp.lessThan.lg ? "2.75rem" : "3.4375rem",
  lineHeight: 1.3,
  fontWeight: 500,
}));
const GradientDisplay = styled(Display)((props) => ({
  background: `-webkit-linear-gradient(0deg, ${gradientColor2}, ${gradientColor1})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}));
const Text = styled(Typography)((props) => ({
  fontSize: props.theme.bp.lessThan.lg ? "1rem" : "1.375rem",
  textAlign: "center",
  lineHeight: 1.5,
  paddingTop: 16,
  paddingBottom: 32,
}));
const StyledButton = styled(Button)((props) => ({
  width: 225,
  height: 45,
  ...(props.theme.bp.lessThan.lg && { marginBottom: 64 }),
}));

// const HeroImg =
//   "https://firebasestorage.googleapis.com/v0/b/waitlist-app-3154b.appspot.com/o/home-page%2Ffloorplan.webp?alt=media&token=680ad28a-dee7-45a6-adc1-19190afc49a0";

export default function Hero() {
  const bp = useBreakpoints();
  const breakLine = bp.lessThan.lg ? "" : <br />;

  const handleClick = () => {
    window.open(BOOK_DEMO_URL, "_blank");
  };

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={HeroImg} />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://firebasestorage.googleapis.com" />
      </Helmet>
      <StyledImageSection>
        <Content>
          <Overline variant="overline">Restaurant Operators</Overline>
          <Display>Delight your customers and</Display>
          <GradientDisplay>supercharge your revenue</GradientDisplay>
          <Text>
            Resto Guru is an all-in-one platform to {breakLine}
            manage restaurant front of house operations
          </Text>
          <StyledButton onClick={handleClick} variant="contained">
            Book a demo
          </StyledButton>
        </Content>

        <div className="block">
          <img fetchpriority="high" src={HeroImg} style={{ width: 900 }} />
        </div>
      </StyledImageSection>
    </>
  );
}
