import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Loadable from "@loadable/component";

import LoaderPage from "pages/LoaderPage";

import AppBar from "../../app-bar/MobileAppBar";
import Footer from "../../footer"; // Footer already lazy loaded
import Hero from "./Hero";

const Root = styled.div({});
const Content = styled.div((props) => ({
  maxWidth: 1184,
  margin: "0px auto",
}));

const Analytics = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteTabletAnalyticsChunk" */ "pages/www/analytics/TabletView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const AutomatedNotifications = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteTabletAutomatedNotificationsChunk" */ "pages/www/automated-waitlist-notifications/TabletView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
// const CaseStudies = Loadable(
//   () =>
//     import(
//       /* webpackChunkName: "WebsiteTabletCaseStudiesChunk" */ "pages/www/case-studies/MobileView.jsx"
//     ),
//   { fallback: () => <LoaderPage /> }
// );
const Customers = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteTabletCustomersChunk" */ "pages/www/customers/index.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const FAQ = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteTabletFAQChunk" */ "pages/www/faq/MobileView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const Modules = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteTabletModulesChunk" */ "pages/www/modules/index.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const Pricing = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteTabletPricingChunk" */ "pages/www/pricing/MobileView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const RequestCallback = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteTabletRequestCallbackChunk" */ "pages/www/request-callback/TabletView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const ReviewHq = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteTabletReviewHqChunk" */ "pages/www/review-hq/TabletView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const Testimonials = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteTabletTestimonialsChunk" */ "pages/www/testimonials/MobileView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);

export default function TabletView() {
  return (
    <Root>
      <AppBar />
      <Content>
        <Hero />
        <Testimonials />
        <Customers css={{ paddingTop: 40 }} />
        <Modules />
        <AutomatedNotifications />
        <Analytics />
        <ReviewHq />
        <Pricing />
        {/* <CaseStudies /> */}
        <FAQ />
        <RequestCallback />
        <Footer />
      </Content>
    </Root>
  );
}
