// import { lighten, darken } from "@material-ui/core/styles/colorManipulator";

// const secondary = "#FF3C0E";

// const lightTheme = {
//   background: {
//     default: "rgb(245,245,245)",
//     paper: "white",
//     light: "#fff",
//     main: "#fff",
//     dark: "rgb(235,235,235)",
//   },
//   iconColor: indigo[500],
//   primary: {
//     light: indigo[300],
//     main: indigo[500],
//     dark: indigo[700],
//   },
//   secondary: {
//     light: orange[300],
//     main: orange[500],
//     dark: orange[700],
//   },
//   text: {
//     primary: "#1C1C1C",
//     secondary: "#696969",
//     tertiary: "rgba(0, 0, 0, 0.45)",
//     disabled: "rgba(0, 0, 0, 0.38)",
//   },
//   divider: "rgba(0, 0, 0, 0.08)",
// };

// export default lightTheme;

import { lighten, darken } from "@material-ui/core/styles/colorManipulator";

const secondary = "#FF3C0E";

const lightTheme = {
  iconColor: secondary,
  palette: {
    type: "light",
    background: {
      paper: "#FFFFFF",
      default: "#F3F3F3",
    },
    primary: {
      light: lighten(secondary, 0.75),
      main: "#FF3C0E",
      dark: darken(secondary, 0.5),
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#BB86FC",
      contrastText: "#FFFFFF",
    },
    tertiary: {
      light: secondary,
      main: secondary,
      dark: secondary,
    },
    text: {
      primary: "#1C1C1C",
      secondary: "#696969",
      tertiary: "rgba(0, 0, 0, 0.45)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    divider: "rgba(0, 0, 0, 0.08)",
  },
};

export default lightTheme;
