import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { Button as MuiButton, Typography } from "@material-ui/core";

import { ImageContainer, ImageSection } from "pages/www/styles";
import { gradientColor1, gradientColor2 } from "pages/www/styles/constants";

import { BOOK_DEMO_URL } from "pages/www/util";

const MobileHero = styled(ImageSection)((props) => ({
  gridTemplateRows: "auto auto",
  gridTemplateColumns: "1fr",
  paddingTop: 56,
  justifyContent: "center",
  marginTop: 32,
}));
const Content = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  alignItems: "flex-start",
  justifySelf: "center",
  padding: "16px 32px",
}));
const Overline = styled(Typography)((props) => ({
  ...props.theme.typography.overline,
  marginBottom: 6,
}));
const Display = styled(Typography)((props) => ({
  ...props.theme.typography.display,
}));
const GradientDisplay = styled(Display)((props) => ({
  background: `-webkit-linear-gradient(0deg, ${gradientColor2}, ${gradientColor1})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: 600,
}));
const Description = styled(Typography)((props) => ({
  fontSize: "1rem",
  textAlign: "left",
  lineHeight: 1.5,
  paddingTop: 16,
  paddingBottom: 32,
}));

const StyledButton = styled(MuiButton)((props) => ({
  width: 225,
  height: 45,
  ...(props.theme.bp.lessThan.lg && { marginBottom: 64 }),
}));
function Button(props) {
  const handleClick = () => {
    window.open(BOOK_DEMO_URL, "_blank");
  };

  return (
    <StyledButton variant="contained" onClick={handleClick} {...props}>
      Book a demo
    </StyledButton>
  );
}

function Img({ className, src }) {
  return <img src={src} style={{ width: "100%" }} />;
}
function Image({ src }) {
  return (
    <ImageContainer css={{ margin: "auto 8px", padding: 6 }}>
      <Img src={src} />
    </ImageContainer>
  );
}

export {
  Button,
  Content,
  Description,
  Display,
  GradientDisplay,
  Overline,
  Image,
};
export default MobileHero;
