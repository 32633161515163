import Loadable from "@loadable/component";

const Footer = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteFooter" */ "pages/www/footer/Footer.jsx"
    ),
  { fallback: () => {} }
);

export default Footer;
