import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";

import { useBreakpoints } from "util/BreakpointProvider";
import HeroImg from "pages/www/assets/floorplan.webp";
import {
  Button,
  Content,
  Description,
  Display,
  GradientDisplay,
  Overline,
  Image,
} from "pages/www/components/MobileHero";
import MobileHero from "pages/www/components/MobileHero";

export default function Hero() {
  const bp = useBreakpoints();
  const breakLine = bp.lessThan.lg ? "" : <br />;

  return (
    <MobileHero>
      <Content>
        <Overline>Restaurant Operators</Overline>
        <Display>Delight your customers and</Display>
        <GradientDisplay>supercharge your revenue</GradientDisplay>
        <Description>
          Resto Guru is an all-in-one platform to {breakLine}
          manage restaurant front of house operations
        </Description>
        <Button />
      </Content>

      <Image src={HeroImg} />
    </MobileHero>
  );
}
