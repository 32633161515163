import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

const StyledDialogTitle = styled(MuiDialogTitle)((props) => ({
  paddingTop: 20,
  paddingBottom: 4,
}));
const Title = styled(Typography)((props) => ({
  ...props.theme.typography.h5,
  lineHeight: 1,
  marginBottom: 0,
}));

export default function DialogTitle({ children }) {
  return (
    <StyledDialogTitle disableTypography>
      <Title>{children}</Title>
    </StyledDialogTitle>
  );
}
