import React from "react";

const INITIAL_VALUE = {
  is: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    infinity: false,
  },
  greaterThan: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    infinity: false,
  },
  lessThan: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    infinity: false,
  }
};

const BreakpointContext = React.createContext(INITIAL_VALUE);

export default BreakpointContext;