/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import MuiButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledCircularProgress = styled(CircularProgress)(() => ({
  color: "white",
}));
const LowEmphasisButton = styled(ButtonBase)((props) => ({
  backgroundColor: "#3F3F3F",
  color: props.theme.palette.text.primary,
  fontWeight: 300,
  letterSpacing: 0.5,
  ...(props.theme.palette.type === "light" && {
    fontWeight: 400,
    backgroundColor: "#FF3C0E",
    color: "#fff",
    "&:hover": {
      backgroundColor: "##d5d5d5",
      color: "#000",
    },
  }),
}));
const StyledButton = styled(ButtonBase)({
  borderRadius: 100,
  boxShadow: "none",
});

function RoundedButton(props) {
  return <StyledButton color="primary" {...props} variant="contained" />;
}

function ButtonBase({ children, disabled, loading, ...others }) {
  const newProps = { ...others };
  if (disabled) newProps["disabled"] = disabled;
  if (loading) newProps["disabled"] = true;
  return (
    <MuiButton {...newProps}>
      {loading ? (
        <StyledCircularProgress className="Button-circularProgress" size={24} />
      ) : (
        children
      )}
    </MuiButton>
  );
}

export default function Button(props) {
  if (props.variant === "rounded") return <RoundedButton {...props} />;
  if (props.variant === "low-emphasis") return <LowEmphasisButton {...props} />;
  return <ButtonBase {...props} />;
}
