import { createTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";

import "static/index.scss";
import lightTheme from "./lightTheme";
import darkTheme from "./darkTheme";
import { gradientColor1, gradientColor2 } from "../styles/constants";

const getTheme = (bp, themeType = "dark") => {
  const ourTheme = themeType === "light" ? lightTheme : darkTheme;
  themeType === "light"
    ? require("static/buttons-light.scss")
    : require("static/buttons-dark.scss");

  return createTheme({
    bp,
    ["mixins.toolbar"]: { minHeight: bp.lessThan.md ? 56 : 64 },
    dark: darkTheme,
    light: lightTheme,
    palette: {
      type: themeType,
      colors: {
        blue: blue[200],
        green: green[200],
        red: red[200],
        orange: orange[200],
      },
      ...ourTheme.palette,
      // error: will use the default color
    },
    constants: ourTheme?.constants ? ourTheme.constants : {},
    typography: {
      useNextVariants: true,
      fontFamily:
        ourTheme?.typography?.fontFamily ??
        ["Noto Sans", "sans-serif"].join(","),
      display: {
        fontSize: "3.4375rem",
        lineHeight: 1.3,
        fontWeight: 500,
        ...(bp.lessThan.lg && {
          fontSize: "2.44rem",
        }),
      },
      h1: {
        color: ourTheme.palette.text.primary,
        fontSize: "3.05rem",
        lineHeight: 1.4,
        fontWeight: 400,
        ...(bp.lessThan.md && {
          fontSize: "2.44rem",
        }),
      },
      h2: {
        color: ourTheme.palette.text.primary,
        fontWeight: 400,
        fontSize: "1.4rem",
        lineHeight: 1.4,
        marginBottom: 16,
        ...(bp.lessThan.lg && {
          fontSize: "2.1rem",
        }),
      },
      h3: {
        color: ourTheme.palette.text.primary,
        fontWeight: 400,
        fontSize: "2.44rem",
        lineHeight: 1.4,
      },
      h4: {
        color: ourTheme.palette.text.primary,
        fontWeight: 400,
        fontSize: "1.95rem",
        lineHeight: 1.4,
      },
      h5: {
        color: ourTheme.palette.text.primary,
        fontWeight: 400,
        fontSize: "1.56rem",
        lineHeight: 1.4,
      },
      h6: {
        color: ourTheme.palette.text.primary,
        fontWeight: 400,
        fontSize: "1.25rem",
        lineHeight: 1.4,
      },
      body1: {
        color: ourTheme.palette.text.primary,
        fontWeight: 300,
        fontSize: "1rem",
        lineHeight: 1.4,
      },
      body2: {
        color: ourTheme.palette.text.secondary,
        fontWeight: 300,
        fontSize: "1rem",
        lineHeight: 1.4,
      },
      button: {
        fontSize: "0.9375rem",
        lineHeight: "0.9375rem",
        fontWeight: 600,
        textTransform: "none",
        background: `-webkit-linear-gradient(0deg, ${gradientColor1}, ${gradientColor2})`,
        color: "black",
        // WebkitBackgroundClip: "text",
        // WebkitTextFillColor: "transparent",
      },
      overline: {
        fontSize: "0.75rem",
        lineHeight: "0.75rem",
        fontWeight: 700,
        letterSpacing: "2px",
        background: `-webkit-linear-gradient(${gradientColor2}, ${gradientColor1})`,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        ...(bp.lessThan.lg && {
          width: "100%",
          textAlign: "left",
          fontSize: "0.65rem",
          lineHeight: 1.5,
          marginBottom: 4,
        }),
      },
      // ...ourTheme?.typography,
    },
    shape: {
      borderRadius: 2,
    },
    overrides: {
      ...ourTheme.overrides,
      MuiAlert: {
        filledSuccess: {
          color: "black",
          fontWeight: 600,
          lineHeight: "18px",
        },
        filledError: {
          color: "black",
          fontWeight: 600,
          lineHeight: "18px",
        },
      },
      MuiButton: {
        root: {
          height: 38,
          whiteSpace: "nowrap",
          padding: "12px 24px",
          color: "black !important",
        },
        text: {
          height: 38,
          padding: "12px 24px",
          background: "unset",
          color: `${ourTheme.palette.text.secondary} !important`,
        },
        outlined: {
          height: 38,
          padding: "11px 23px",
        },
        outlinedPrimary: {
          height: 38,
          borderColor: "rgba(255,255,255,0.23)",
          backgroundColor: fade(ourTheme.palette.primary.main, 0.07),
        },
        outlinedSecondary: {
          height: 38,
          borderColor: "rgba(255,255,255,0.23)",
          backgroundColor: fade(ourTheme.palette.secondary.main, 0.07),
        },
        containedPrimary: {
          height: 38,
          color: "black",
          boxShadow: "none",
        },
        containedSecondary: {
          height: 38,
          color: "black",
          boxShadow: "none",
        },
      },
      MuiCssBaseline: {
        "@global": {
          ...ourTheme.baseline,
          "*::-webkit-scrollbar": {
            width: 3,
            height: 3,
          },
          "*::-webkit-scrollbar-track": {
            background: "transparent",
          },

          "*::-webkit-scrollbar-thumb": {
            backgroundColor: fade(ourTheme.palette?.text.disabled, 0.5),
            borderRadius: 10,
            border: "1px solid transparent",
          },
          "*": {
            scrollbarWidth: "thin",
            scrollbarColor: `${fade(
              ourTheme.palette?.text.disabled,
              0.5
            )} transparent`,
          },
        },
      },
    },
  });
};

export default getTheme;
